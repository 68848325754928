<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="12">
                <v-form class="custom-form" @submit.prevent="saveDepartment" ref="departmentForm">
                    <v-card :elevation="1" color="brown lighten-4">
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-toolbar color="#546E7A" :elevation="1" height="30px" dark>
                                    <v-toolbar-title class="subtitle-2">Departments</v-toolbar-title>
                                </v-toolbar>
                                <v-card-text class="py-0 mt-1">
                                    <v-row no-gutters>
                                        <v-col cols="4" offset="4">
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3">Name </v-col>
                                                <v-col cols="9">
                                                    <v-text-field
                                                        v-model="department.name"
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :rules="[() => !!department.name || 'Enter name']"
                                                        required
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters>
                                                <v-col cols="3">Description </v-col>
                                                <v-col cols="9">
                                                    <v-textarea
                                                        v-model="department.description"
                                                        dense
                                                        outlined
                                                        hide-details
                                                        height="7vh"
                                                    ></v-textarea>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters>
                                                <v-col cols="3"></v-col>
                                                <v-col cols="9">
                                                    <v-row dense>
                                                         <v-col cols="6">
                                                            <v-btn height="30px" type="reset" dark block color="deep-orange">Clear</v-btn>
                                                        </v-col>
                                                        <v-col cols="6">
                                                            <v-btn height="30px" type="submit" :loading="waitingForSave" dark block color="light-blue darken-2">Save</v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
        <v-row dense class="mt-1">
            <v-col cols="12">
                <v-data-table
                    class="custom-data-table elevation-1"
                    dense
                    :headers="departmentHeaders"
                    :loading="$store.getters['department/loading']"
                    :items="$store.getters['department/departments']"
                    :search="searchDepartment"
                >
                    <template v-slot:top>
                        <v-toolbar dense color="white" :elevation="1" height="30px">
                            <v-toolbar-title class="subtitle-2">Department List</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-form class="custom-form">
                                <v-text-field
                                    outlined
                                    dense
                                    hide-details
                                    placeholder="Search Designation"
                                    append-icon="mdi-magnify"
                                    style="width:300px;"
                                    v-model="searchDepartment"
                                >
                                </v-text-field>
                            </v-form>
                        </v-toolbar>
                    </template>
                    <template v-slot:[`item.action`]="{ item }">
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-icon small @click="editDepartment(item)" color="primary" v-on="on">mdi-circle-edit-outline</v-icon>
							</template>
							<span>Edit</span>
						</v-tooltip>
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-icon small @click="departmentId = item.id;$refs.confirmDialog.dialog = true" color="error" v-on="on" >mdi-delete-circle-outline</v-icon>
							</template>
							<span>Delete</span>
						</v-tooltip>
					</template>
                </v-data-table>
                <confirm-dialog ref="confirmDialog" @confirm="deleteDepartment"></confirm-dialog>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import confirmDialog from "./../../components/Confirm";

export default {
    name: 'Departments',
    components: {
		"confirm-dialog": confirmDialog,
    },

    data: ()=> ({
        department: {
            id: null,
            name: '',
            description: ''
        },

        departmentHeaders: [
            { text: 'Sl', value: 'sl' },
            { text: 'Name', value: 'name' },
            { text: 'Description', value: 'description' },
            { text: 'Action ', value: 'action' },
        ],
        searchDepartment: '',
        departmentId: null,
        waitingForSave: false,
    }),

    created() {
        this.getDepartment();
    },

    methods: {
        getDepartment() {
            this.$store.dispatch('department/getDepartments')
        },

        async saveDepartment() {
            if(! this.validationForm()) {
                return;
            }

            this.waitingForSave = true

            let Success = await this.$store.dispatch('department/saveDepartment', this.department);

            if(Success) {
                this.resetForm();
            }

            this.waitingForSave = false
        },

        async editDepartment(department) {
            Object.keys(this.department).forEach(key => this.department[key] = department[key]);
        },

        deleteDepartment() {
            this.$store.dispatch('department/deleteDepartment', { id: this.departmentId });
            this.$refs.confirmDialog.dialog = false;
        },

        validationForm() {
            let isValid = true;
            this.$refs.departmentForm.validate();
            this.$refs.departmentForm.inputs.forEach(input => {
                if(input.hasError) isValid = false;
            })

            return isValid;
        },

        resetForm() {
            this.department.name = '';
            this.department.description = '';
            this.$refs.departmentForm.reset();
            this.$refs.departmentForm.resetValidation();
        }
    }
}
</script>